<template>
   <div class="backdrop">
    <div class="b-modal">
      <div class="modal-header">
        <div class="modal-title text-light">
          Scan QR
        </div>
        <p class="text-white"><span @click.self="closeModal()">x</span></p>
      </div>
      <div class="box">
        <div>
            <div class="container p-5">
               <b-card class="text-center">
                    <div class="bg-secondary text-light">
                      <img class="" style="width: 100%;" src="@/assets/gcash.jpg"/>
                    </div>
                </b-card>
            </div>
            <div class="done-btn container"><a href="/"><b-button>DONE</b-button></a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
    name: "Confirm",
    props:["customerName"],
  data() {
    return {
      showSubmitModal: false,
    };
  },
   computed: {
    confirmDetails() {
      return this.$store.state.confirmDetails;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>
.b-modal{
  width: 400px;
  height: auto;
}
.box{
  width: 100%;
}
.done-btn{
    display: flex;
    justify-content: center;
    padding: 30px;
}

</style>