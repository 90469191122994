<template>
<div class="containerB d-flex align-items-stretch flex-wrap">
    <!-- <div class="category" align="center">
           <h3 style="font-size:4vw;">Exposing one’s self through art is very liberating—it gives permission to vulnerability. Art comforts the senses and purges them from unwanted tensions. I like to think my work depicts controlled chaos; most are colorful and vibrant yet give an overall cohesive effect.</h3>
       </div> -->
    <div id="product-List-one" class="col-md-12 containerBG">
        <div class="container-fluid">
         <div class="d-flex align-items-stretch flex-wrap productList col-md-12 mx-auto">
            <ProductCard style="width: 20rem"
            v-for="product in products"
            :key="product.id"
            :product="product"/>
            
        </div>
        </div>
    </div>
</div>
    
</template>
<script>

import ProductCard from './ProductCard.vue';


export default {
    name: 'ProductList',
    components: {
        ProductCard,
        },
    computed:{
        products(){
            return this.$store.state.products;
        }
    }
}
</script>
<style scoped>
h3{
    padding: 100px;
    color: #b6b6b6 !important;
    font-size: 30px;
    font-weight: lighter;
}



</style>