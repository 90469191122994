<template>
<div class="dashboard container-fluid d-flex col-12">
    
    <div class="title col-md-6">
        <div>
            <h2 class="text-white" style="font-size:5vw;">“Art comforts the senses and purges them from unwanted tensions.”</h2>
        </div>
        <!-- <div class="buttons">
            <button class="btn btn-dark mr-sm-2" type="button" @click="loginModal()">LOGIN</button>
            <button class="btn btn-light mr-sm-0" type="button" @click="signupModal()">REGISTER</button>
        </div> -->
    </div>
    <div class="media-title col-6 mt-5">
        <img src="@/assets/feat-art.png"/>
    </div>

    <div>
        <Signup @close="signupModal" v-if="showSignupModal"> </Signup>
    </div>
    <div>
        <Login @close="loginModal" v-if="showLoginModal"> </Login>
    </div>

    
</div>

</template>
<script>
import Signup from "./modals/Signup.vue";
import Login from "./modals/Login.vue";

export default {
    name: 'Dashboard',
    components: {
       Signup,
       Login
    },
    data(){
        return {
            showLoginModal: false,
            showSignupModal: false,
        }
    },
    methods: {
        loginModal() {
        this.showLoginModal = !this.showLoginModal;
        },
        signupModal() {
        this.showSignupModal = !this.showSignupModal;
        },
    },
}
</script>
<style scoped>
.dashboard{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
   
}
.title{
    width: 40%;
    margin: 30px;
    display: block;
    justify-content: space-evenly;
    align-items: center;
     padding-top: 10px !important;
    padding-left: 50px !important;
}
.title h1{
    width: 120%;
    margin: 20px;
    display: flex;
    justify-content: center;
    font-size: 50px !important;
   
}
.title .buttons{
    display: flex;
    margin-right: 10px;
    width: 2rem;
}
.media-title{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
     padding-top: 10px !important;
    padding-left: 50px !important;
}
.media-title img{
    width: 50%;
}

</style>