<template>
   <div class="containerB d-flex align-items-stretch flex-wrap">
      <form class="col-12">

            <label class="category col-12 text-light container-fluid px-5"><b-icon @click="goBack2()" class="h2" icon="arrow-left-square"></b-icon><h3>Checkout:</h3></label>
            <div class="d-flex col-md-12">
              <b-card-group class="container col-md-12" align="center">
              <b-card class="col-md-6 d-flex flex-wrap" v-if="cart">
                <b-card-body class="text-dark">
                  <h6 class="text-dark">{{cart.title}}</h6>
                  <img style="width:30rem;" :src="cart.image" :alt="cart.title"/>
                  <p class="text-dark">{{cart.description}}</p>
                  <p class="text-dark">{{cart.price}}</p>
                  <p class="text-dark">{{cart.type}}</p>
                </b-card-body>
              </b-card>
              
              <b-card class="col-md-6 pt-5">
                <div>
                  <img class="" style="width: 100px;" src="@/assets/gcash.jpg"/>
                  Scan this QR code using your GCASH, MAYA or QRPH.
                  <span><button class="btn btn-primary" @click.prevent="openQrCode()">pay</button></span>
                </div>
               
                <b-form-group class="text-dark p-3"
                label="Enter a valid email address to receive the digital art."
                label-for="emailOrMobileInput"
               >
                <b-form-input id="emailOrMobileInput" v-model="customer.emailOrMobile" required ></b-form-input>
                <p class="danger" v-if="errors.emailOrMobile != ''">{{errors.emailOrMobile}}</p>
                </b-form-group>

                <b-form-group class="text-dark p-3" 
                label="Kindly enter the reference number for proof of payment."
                label-for="nameInput"
                >
                <b-form-input name="name" type="text" id="nameInput" v-model="customer.customerName" required minlength="6"></b-form-input>
                <p class="danger" v-if="errors.customerName != ''">{{errors.customerName}}</p>
                </b-form-group>

                <div class="text-dark  p-3">
                <label>Special Instructions (Optional)</label>
                <b-form-textarea 
                  id="textarea"
                  v-model="customer.message"
                  placeholder="Write your comment."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </div>
              </b-card>
              </b-card-group>

            </div>
            
            <div @click.prevent="confirm()" class="submit-btn container pt-3"><b-button type="submit">SUBMIT</b-button></div>
           </form>

           <Confirm v-if="showConfirm" :customerName="customer.customerName"></Confirm>
           <Qrcode v-if="showQrCode" @close="openQrCode"/>
          <Footer/>
          <NotFound v-if="showNotFound"></NotFound>
  </div>
  
</template>

<script>
import Confirm from './Confirm.vue';
import Footer from '../Footer.vue';
import NotFound from '../NotFound.vue';
import Qrcode from '../../components/modals/Qrcode.vue'


export default {
  components: { Confirm, Footer, NotFound, Qrcode },
    name: "Buy",
    data() {
    return {
      showBuyModal: false,
      showQrCode: false,
      customer: {
          customerName:"",
          emailOrMobile:"",
          message: "",
        },
      showConfirm: false,
      showNotFound: false,
      errors: {
        customerName: "",
        emailOrMobile: ""
      },
       
      
    };
  },
 
  computed: {
    cart() {
      return this.$store.state.cart;
    },
  },
  created: function(){
    let loaded = JSON.parse(localStorage.getItem('cart'));

    if(loaded) {
      this.cart.title = loaded.title;
      this.cart.image = loaded.image;
      this.cart.price = loaded.price;
      this.cart.description = loaded.description;
      this.cart.id = loaded.id;
      }
  },
 
  methods: {
    openQrCode(){
      this.showQrCode = !this.showQrCode
    },
    closeBtnBuy() {
      this.$emit("close");
    },
    goBack2() {
           this.$router.go(-1);
        },
    validateEmail: function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
    confirm() {
    
      let hasError = false;
      if (!this.validateEmail(this.customer.emailOrMobile)) {
          this.errors.emailOrMobile = "Please provide a valid Email Address.";
          hasError = true;
      }
      if (this.customer.customerName < 6 || this.customer.customerName == "" || this.customer.customerName == undefined || this.customer.customerName == null){
          hasError = true;
          this.errors.customerName = "Please enter a valid reference ID."
          setTimeout(()=>{
            hasError = false;
          this.errors.customerName = ""
          }, 3000)
      
        
      }
      if ( this.customer.emailOrMobile == "" || this.customer.emailOrMobile == undefined || this.customer.emailOrMobile == null){
         hasError = true;
         this.errors.emailOrMobile = "Contact details is empty."
         setTimeout(()=>{
            hasError = false;
          this.errors.emailOrMobile = ""
          }, 3000)
        
      }
      if ( this.cart.id > 22 ){
        hasError = true;
        console.log("id not found.")
        setTimeout(()=>{
            hasError = false;
        
          }, 3000)
      }
     

      if (!hasError ){
          const params = {
          productId: this.cart.id,
          customerName: this.customer.customerName,
          emailOrMobile: this.customer.emailOrMobile,
          message: this.customer.message,
        }

        const data = Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join('&');
   

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data,
          url: '/cx/salart/buy',
        };

          this.axios(options)
          .then((response) => {
            this.showConfirm = true;
             console.log(response)
            }).catch((err) => {
                
              console.log(err) 

            })
      }
      
      }

    }
  }


</script>

<style scoped>

form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

}
.submit-btn{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}
.danger{
  color: red;
}

</style>