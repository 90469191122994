import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex, axios);

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    product: [],
    products:[
      {id: '17',title: 'Commission1',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com1.png'}, 
      {id: '18',title: 'Commission2',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com2.png'},
      {id: '19',title: 'Commission3',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com3.png'},
      {id: '20',title: 'Commission4',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com4.png'},
      {id: '1',title: 'Chiori',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/1.jpg'},
      {id: '2',title: 'OC No. 1',description: 'Digital Art',price: 'Php 300.00',type: 'artwork', image:'/assets/laraArt/8.jpg'},
      {id: '3',title: 'Robin',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/9.png'},
      {id: '4',title: 'Pixel Art',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/10.png'},
      {id: '5',title: 'OC No. 2',description: 'Digital Art',price: 'Php 300.00',type: 'artwork', image:'/assets/laraArt/11.png'},
      {id: '6',title: 'Bella',description: 'Digital Art',price: 'Php 300.00',type: 'artwork', image:'/assets/laraArt/12.jpg'},
      {id: '7',title: 'Base',description: 'Digital Art',price: 'Php 300.00',type: 'artwork', image:'/assets/laraArt/2.jpeg'},
      {id: '8',title: 'Alhaitham',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/3.jpeg'},
      {id: '9',title: 'Kokomi',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/4.jpeg'},
      {id: '10',title: 'Blossom',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/5.jpeg'},
      {id: '11',title: 'Buttercup',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/6.jpeg'},
      {id: '12',title: 'Bubbles',description: 'Digital Art',price: 'Php 150.00',type: 'artwork', image:'/assets/laraArt/7.jpeg'},
      {id: '13',title: 'Commission1',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com1.png'},
      {id: '14',title: 'Commission2',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com2.png'},
      {id: '15',title: 'Commission3',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com3.png'},
      {id: '16',title: 'Commission4',description: 'Digital Art',price: 'Starts at Php 350.00',type: 'commission', image:'/assets/laraArt/com4.png'},
    ],
      productId:"",
      login:{
        customerName:"",
        emailOrMobile:"",
        message:""
      },
    cart: [],
    confirmDetails:[]
   
  },
  getters:{
    
  },
  mutations: {
    GET_PRODUCT(state, payload){
      state.product = payload;
    },
    INIT_CART(state, payload) {
      state.cart = payload;
    },
    CONFIRM(state, payload){
      state.confirmDetails = payload;
    }
  },
  actions: {
    login({commit}, login){
      commit("LOGIN", login)
    },

    viewProduct({ commit },id, image, title, size, description, type) {
      commit("GET_PRODUCT", {id, image, title, size, description, type
      })
    },

    buyItem({commit}, {id, image, title, size, description, quantity,type}
      ) {
      commit("INIT_CART", {id, image, title, size, description, quantity, type})
    },

    confirmBuy({commit},{productId, customerName, emailOrMobile, message, type}
      ) {
      commit("CONFIRM",{productId, customerName, emailOrMobile, message, type})
    }

  },
  modules: {},
});