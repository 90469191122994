<template>
   <div class="backdrop">
    <div class="b-modal">
      <div class="modal-header">
        <div class="modal-title text-white">
          
        </div>
        
            <div class="container p-5" v-if="product">
               <b-card>
                    
                   <image-zoom class="zoom-card"
                      :regular="product.image" 
                      :zoom="product.image"
                      :zoom-amount="3" 
                      img-class="img-fluid">
                   <!-- <img :src="product.image" :alt="product.title" class="card-img-bottom"> -->
                   </image-zoom>
                </b-card>
                <b-card-footer><p class="text-white"><button class="btn-exit" @click.self="closeBtnProduct()">exit</button></p></b-card-footer>
            </div>
           
        </div>
      </div>

  </div>
</template>

<script>
import imageZoom from 'vue-image-zoomer';


export default {
    name: 'Product',
    components:{imageZoom},
    computed:{
      product() {
      return this.$store.state.product;
      },
    },

    created: function(){
    let show = JSON.parse(localStorage.getItem('product'));

    if(show) {
      this.product.id = show.id;
      this.product.title = show.title;
      this.product.image = show.image;
      this.product.price = show.price;
      this.product.description = show.description;
      this.product.type = show.type;
      }
  },

    methods: {
        goBack() {
           this.$router.go(-1);
        },
        closeBtnProduct(){
            this.$emit("close");
        }
    },
}
</script>
<style scoped>
.zoom-card{
  display: flex;
  justify-content: center;
  margin: 0 0 !important;
}
.b-modal{
  width: 500px;
  height: auto;
}
.img-btn img{
    width: 10rem;
    display: flex;
    justify-content: space-between; 
}
.btn-exit{
  border-radius: 6px;
  padding: 6px 10px;
}
@media screen and (max-width: 480px) {
  .b-modal{
  width: 350px;
  height: auto;
}
}

</style>