<template>
    <div class="footerBG">
        <p>&copy; 2024 LARA.ART</p>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>
<style scoped>

</style>